export const getCsrfToken = () => {
  let csrfToken = null;
  if (document.cookie) {
    document.cookie.split(';').forEach((cookie) => {
      if (cookie.trim().startsWith('csrftoken=')) {
        csrfToken = cookie.trim().substring('csrftoken='.length);
      }
    });
  }
  return csrfToken;
};

export default getCsrfToken;
