import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import { DocumentProvider } from './context/DocumentContext';

// Lazy load components
const LeftBar = lazy(() => import('./components/bars/LeftBar'));
const TopBar = lazy(() => import('./components/bars/TopBar'));
const CommercialHome = lazy(() => import('./components/CommercialHome'));
const HomePage = lazy(() => import('./components/HomePage'));
const Login = lazy(() => import('./components/Login'));
const WorkPage = lazy(() => import('./components/WorkPage'));

const App = () => {
  return (
    <DocumentProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<CommercialHome />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <>
                    <TopBar />
                    <LeftBar />
                    <HomePage />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/:docId"
              element={
                <ProtectedRoute>
                  <>
                    <TopBar />
                    <LeftBar />
                    <WorkPageWrapper />
                  </>
                </ProtectedRoute>
              }
            />
            {/* Catch-all route for redirecting to the root path */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </DocumentProvider>
  );
};

const WorkPageWrapper = () => {
  const { docId } = useParams();
  return <WorkPage docId={docId} />;
};

export default App;
