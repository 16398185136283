import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

// Helper function to decode the JWT token
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

const isTokenValid = (token) => {
  if (!token) return false;

  const decodedToken = parseJwt(token);
  if (!decodedToken) return false;

  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  return decodedToken.exp && decodedToken.exp > currentTime;
};

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem('access_token');

  // Check if token exists and is valid
  if (!accessToken || !isTokenValid(accessToken)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
