import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useCallback } from 'react';

import axiosInstance from '../components/axiosConfig';

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/app/documents/');
      if (response && response.data && Array.isArray(response.data)) {
        setDocuments(response.data);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Failed to fetch documents.');
      throw err;
    }
  }, []);

  const addDocument = useCallback((newDocument) => {
    setDocuments((prevDocuments) => {
      return [newDocument, ...prevDocuments];
    });
  }, []);

  const renameDocument = useCallback((docId, newName) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) => (doc.id === docId ? { ...doc, name: newName } : doc)),
    );
  }, []);

  return (
    <DocumentContext.Provider
      value={{ documents, fetchDocuments, addDocument, renameDocument, error }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

DocumentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDocuments = () => useContext(DocumentContext);
